export const siteConfig = {
  "name": "Skill Legends Royale",
  "slogan": "slogan",
  "domain": "https://www.skilllegendsroyale.com",
  "icon": "/favicon.ico",
  "appleIcon": "/apple-touch-icon.png",
  "theme": {
    "headFont": null,
    "primaryColor": null,
    "fontColor": null,
    "backgroundColor": null
  },
  "gaId": "G-XXXXXXXXXX",
  "plausible": "",
  "navbarItems": [
    {
      "title": "home",
      "href": "/"
    },
    {
      "title": "guide",
      "href": "/guide"
    },
    {
      "title": "information",
      "href": "/t"
    }
  ],
  "gameType": "download",
  "gameIframeUrl": "",
  "gameDownloadBg": "",
  "gameDownloadUrl": "https://store.steampowered.com/app/3114410/_/?packageid=undefined",
  "isShowFAQs": true,
  "isShowVideo": true,
  "isShowGuide": true,
  "videos": [
    {
      "title": "Skill Legends Royale",
      "url": "https://video.akamai.steamstatic.com/store_trailers/257040776/movie480_vp9.webm?t=1725874735"
    }
  ],
  "isShowComments": true,
  "isShowTweets": false,
  "useRealTweets": null,
  "tweets": [
    ""
  ],
  "comments": [
    {
      "author": "Lorenz S.",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Lorenz S..png",
      "content": "This game is so much fun! The combination of rogue-like and auto chess elements is really addictive. I love the fact that you can build your own deck of heroes and treasures and battle it out against other players. The graphics are simple but charming, and the sound effects are great. Definitely one of the best games I've played in a while!"
    },
    {
      "author": "MK",
      "role": "player",
      "avatar": "https://api.multiavatar.com/MK.png",
      "content": "This game is amazing! The battles are intense and strategic, and the graphics are really cool. I love the fact that you can collect different heroes and treasures and build your own deck. The multiplayer mode is also a lot of fun, and I've been playing with friends a lot. Highly recommended!"
    },
    {
      "author": "Ben Stephens \u2014 \ud83d\udc3f\ufe0f/acc",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Ben Stephens \u2014 \ud83d\udc3f\ufe0f/acc.png",
      "content": "I'm having a blast with this game! The auto chess mechanics are really well done, and the rogue-like elements add a nice layer of unpredictability. The graphics are simple but charming, and the sound effects are great. The multiplayer mode is also a lot of fun, and I've been playing with friends a lot. Definitely worth checking out!"
    },
    {
      "author": "Packy McCormick",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Packy McCormick.png",
      "content": "This game is so addictive! The battles are intense and strategic, and the graphics are really cool. I love the fact that you can collect different heroes and treasures and build your own deck. The multiplayer mode is also a lot of fun, and I've been playing with friends a lot. Highly recommended!"
    },
    {
      "author": "Mike Taylor",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Mike Taylor.png",
      "content": "This game is amazing! The battles are intense and strategic, and the graphics are really cool. I love the fact that you can collect different heroes and treasures and build your own deck. The multiplayer mode is also a lot of fun, and I've been playing with friends a lot. Highly recommended!"
    },
    {
      "author": "Lex Fridman",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Lex Fridman.png",
      "content": "This game is so much fun! The combination of rogue-like and auto chess elements is really addictive. I love the fact that you can build your own deck of heroes and treasures and battle it out against other players. The graphics are simple but charming, and the sound effects are great. Definitely one of the best games I've played in a while!"
    }
  ],
  "isShowRecommendation": true,
  "friendLinks": [
    {
      "title": "",
      "url": ""
    }
  ],
  "recommendations": {
    "en": [
      {
        "title": "",
        "cover": "",
        "url": ""
      }
    ]
  },
  "faviconBgColor": null,
  "faviconTextColor": null,
  "faviconText": "SL"
};
